$orange-focus-blur: 0 0 $input-btn-focus-blur $input-btn-focus-width
  rgba(map-get($theme-colors, 'warning'), $input-btn-focus-color-opacity);

.form-control-orange {
  &:focus {
    border-color: $warning;
    box-shadow: $orange-focus-blur;
  }
}

.form-control-rounded {
  @extend .rounded-pill;
}

.form-check-input.form-check-input-orange,
.was-validated .form-check-input.form-check-input-orange {
  &:focus {
    border-color: map-get($theme-colors, 'warning');
    box-shadow: $orange-focus-blur;
  }

  &:checked,
  &[type='checkbox']:indeterminate {
    background-color: map-get($theme-colors, 'warning') !important;
    border-color: map-get($theme-colors, 'warning');
  }
}

.was-validated .form-check-input.form-check-input-orange {
  &.is-valid,
  &:valid {
    border-color: map-get($theme-colors, 'warning');
  }
}

// Make sure success and error states are passed to the hints
.form-control.is-valid + .form-text {
  @extend .valid-feedback;
}

.form-control.is-invalid + .form-text {
  @extend .invalid-feedback;
}

// Make sure placeholder in selects has greyed out color
.form-select[data-is-placeholder='true'] {
  color: $input-placeholder-color;
}

.form-label,
.form-text {
  &.is-invalid {
    color: $form-feedback-invalid-color;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fffbf6 inset;
}

// Used to hide honey-pot form elements
.form-extra {
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label,
.was-validated .custom-control-input:valid ~ .form-label,
.custom-control-input.is-valid ~ .form-label {
  color: inherit;
}

.form-control-without-shadow {
  &:focus {
    box-shadow: none;
    border-color: $gray-800;
  }
}

:root {
  --#{$prefix}input-bg-color: #{$white};
  --sl-form-switch-height: 24px;
  --sl-form-switch-width: 40px;
}

.signup-select {
  // An ideal selector would be :has(option[value='']:checked) but :has is not implemented fully in Firefox yet.
  // So only a JS solution works here.
  &.placeholder-selected {
    color: $gray-700;
    background-color: $white;
    border-color: $gray-400;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.875 2.21875L9.75 8.92188C9.51562 9.15625 9.23438 9.25 9 9.25C8.71875 9.25 8.4375 9.15625 8.20312 8.96875L1.07812 2.21875C0.609375 1.79688 0.609375 1.09375 1.03125 0.625C1.45312 0.15625 2.15625 0.15625 2.625 0.578125L9 6.57812L15.3281 0.578125C15.7969 0.15625 16.5 0.15625 16.9219 0.625C17.3438 1.09375 17.3438 1.79688 16.875 2.21875Z' fill='%2300C78D'/%3E%3C/svg%3E");
  }

  &.option-selected {
    color: $green-900;
    background-color: $green-100;
    border-color: $primary;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5 2.04688L9.75 14.7969C9.5625 15.0312 9.28125 15.125 9 15.125C8.67188 15.125 8.39062 15.0312 8.20312 14.7969L1.45312 8.04688C0.984375 7.625 0.984375 6.92188 1.45312 6.5C1.875 6.03125 2.57812 6.03125 3 6.5L9 12.4531L20.9531 0.5C21.375 0.03125 22.0781 0.03125 22.5 0.5C22.9688 0.921875 22.9688 1.625 22.5 2.04688Z' fill='%2300C78D'/%3E%3C/svg%3E");
  }

  & option {
    color: $gray-800;
  }
}

// A change in the rich text editor forced the use of form-control there
// This could carry some other unforeseen selectors so to avoid risk,
// we're creating this duplicate and using the duplicate
.sl-form-control {
  @extend .form-control;
}

.form-control[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 height=%271em%27 viewBox=%270 0 384 512%27%3E%3Cpath d=%27M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z%27/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  cursor: pointer;
}

;@import "sass-embedded-legacy-load-done:1937";