// this is done this way because we want a
// specific behaviour in landing page from where the footer is imported
.container-md-and-xxl {
  @extend .container-xxl;
}

// Removes the yellow background on hover from the editable inputs.
// If we decide to remove it from all places then this selector can be removed too.
.remove-editable-effect .editable:hover,
.remove-editable-effect.editable-input:hover,
.remove-editable-effect.editable:hover {
  background-color: transparent !important;
}

.full-page-container .alert {
  width: max-content;
  margin: 0 auto;
}

// Buttons with only an icon and no text placed in a block container become smaller than expected.
// (It works fine in a flex container).
// We can fix these cases with this temporary class but a more systematic approach would be ideal.
.btn-strict-height {
  height: 35px;

  &.btn-sm {
    height: 28px;
  }

  &.btn-lg {
    height: 42px;
  }
}

// CSS modules doesn't work on /account for TeamSelector.tsx. It generates the class but doesn't add the CSS.
.team-selector-teams {
  // To show max 6 teams + a peek to the 7th.
  max-height: 241px;
}

;@import "sass-embedded-legacy-load-done:2200";