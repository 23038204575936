.empty-layout {
  margin-top: 20px;

  .main-content {
    margin-top: 70px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 45px;

    .main-content {
      margin-top: 75px;
    }

    &.-with-pebble {
      background-image: url('images/elements/pebble-big-gradient.svg');
      background-repeat: no-repeat;
      background-position: 50% 415px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2181";